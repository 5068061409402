import { FunctionalComponent, h } from '@stencil/core';
import { href } from 'stencil-router-v2';
import get from 'lodash/get'
interface AppLink {
    data,
    className
}


export const AppLink: FunctionalComponent<AppLink> = ({ data, className }, children) => {

    if (!data) return children;
    const { Alt, Icon, Label, NewTab, URL, HideLabel, IconInvert } = data;
    const slug = get(data, 'page.data.attributes.Slug') || '';
    const icon = Icon ? (<span class={`${className.includes('uk-icon-button') ? 'uk-icon-button' : 'uk-margin-small-right uk-icon'} ${IconInvert ? 'uk-margin-left uk-flex-1 uk-text-right' : ''}`} uk-icon={Icon}></span>) : '';
    const link = URL && (URL.startsWith('http') || URL.startsWith('mailto:') || URL.startsWith('tel:')) ? { href: URL } : href(URL || '/' + slug)
    const cleanClass = URL && (URL.startsWith('http') || URL.startsWith('mailto:') || URL.startsWith('tel:')) ? className.replace('uk-navbar-dropdown-close', '').replace('uk-offcanvas-close','') : className;
    return (<a {...link} class={`uk-position-relative ${cleanClass.replace('uk-icon-button', '')}`} aria-label={Alt} target={`${NewTab ? '_blank' : ''}`} rel={`${NewTab ? 'noopener noreferrer' : ''}`}>{children && children.length ? children : IconInvert ? [HideLabel ? '' : Label, icon] : [icon, HideLabel ? '': Label ]}</a>);
};