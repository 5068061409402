import { FunctionalComponent, h } from '@stencil/core';
import { AppLink } from './app.link';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

interface SectionFeed {
    data?: any;
}

export const SectionFeed: FunctionalComponent<SectionFeed> = ({ data }) => {

    const { feedItems = [], oppItems = [] } = data;
    return (
        <div id="dealer-feed" uk-offcanvas="flip:true; overlay:true; mode:push">
            <div class="uk-offcanvas-bar wsio-feed">
                <div class="uk-flex uk-flex-top"><h2 class='uk-flex-1 uk-heading-medium'>Notifications</h2> <button class="uk-offcanvas-close" type="button" uk-close></button></div>
                <div class="uk-flex uk-flex-column uk-position-relative uk-margin-top uk-overflow-auto">
                    <div hidden={feedItems.length == 0}>
                        <ul class="uk-list uk-list-large">
                            {
                                feedItems.map(({ createdAt, Title, Link }) =>
                                    <li class="wsio-card uk-padding-small">
                                        <div>
                                            <div class="uk-flex">
                                                <span class="uk-margin-small-right" uk-icon='rss'></span>
                                                <div>News</div>
                                            </div>
                                            <p class="uk-margin-small-top uk-margin-small-bottom">{Title}</p>
                                            <div class="uk-flex uk-flex-middle">
                                                <div class="uk-flex-1">
                                                    <AppLink className='uk-button uk-button-text uk-button-small uk-offcanvas-close' data={Link}></AppLink>
                                                </div>
                                                <div class="uk-text-meta">{dayjs(createdAt).fromNow()}</div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div hidden={oppItems.length == 0}>
                        <ul class="uk-list uk-list-large">
                            {
                                oppItems.map(({ createdAt, Title, Reference }) =>
                                    <li class="wsio-card uk-padding-small">
                                        <div>
                                            <div class="uk-flex">
                                                <span class="uk-margin-small-right" uk-icon='whatsapp'></span>
                                                <div>New Opportunity</div>
                                            </div>
                                            <p class="uk-margin-small-top uk-margin-small-bottom">{Title}</p>
                                            <div class="uk-flex uk-flex-middle">
                                                <div class="uk-flex-1">
                                                    <AppLink className='uk-button uk-button-text uk-button-small uk-offcanvas-close' data={{ URL: `/opportunities?page=1&search=${Reference}` }}>View</AppLink>
                                                </div>
                                                <div class="uk-text-meta">{dayjs(createdAt).fromNow()}</div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>

                    </div>
                </div >
            </div>
            
        </div >
    )
};


